/* eslint-disable array-callback-return */
import { collection, getCountFromServer, getFirestore } from 'firebase/firestore';
import {
  GCP_API_URL,
  ELASTIC_SEARCH_SEARCH_KEY,
  ANALYTICS_API,
  STORE_ID,
  ELASTIC_SEARCH_API_URL,
  ELASTIC_SEARCH_COLLECTION_URI,
  LEDGER_API_URL,
  TOP_COLLECTION_API_URL,
} from '../app/index';
import Service from '../app/service';
import Common from '../helpers/common.helper';
import { app } from '../firebase';
import axios from 'axios';
const db = getFirestore(app);

export const addMarketplaceAssets = (payload) => ({
  type: 'SET_MARKETPLACE_LISTING',
  payload,
});
export const addMarketplaceCollections = (data) => ({
  type: 'SET_COLLECTION_LISTING',
  payload: data,
});
export const collectionReset = (data) => ({
  type: 'RESET_COLLECTION',
});

export const addNftListingFilters = (payload) => ({
  type: 'NFT_LISTING_FILTERS',
  payload,
});

export const addMoreAssets = (payload) => ({
  type: 'ADD_ASSETS',
  payload,
});

export const addLatestCollections = (payload) => ({
  type: 'LATEST_COLLECTIONS_LIST',
  payload,
});

export const addMarketplaceFilters = (filters) => ({
  type: 'SET_MARKETPLACE_FILTERS',
  filters,
});

export const toggleMarketplaceAssetsLoading = (is_loading) => ({
  type: 'TOGGLE_LOADING',
  is_loading,
});

// NFTs page listing
export const addMarketplaceNfts = (payload) => ({
  type: 'SET_NFTS_LISTING',
  payload,
});

export const addMoreNfts = (payload) => ({
  type: 'ADD_NFTS',
  payload,
});

export const addCollectionNftFilters = (filters) => ({
  type: 'SET_NFTS_LISTING_FILTERS',
  filters,
});

export const addPopularCollectionFilters = (filters) => ({
  type: 'SET_POPULAR_LISTING_QUERY',
  filters,
});

export const addProfileNftFilters = (filters) => ({
  type: 'SET_PROFILE_NFTS_LISTING_FILTERS',
  filters,
});

export const resetProfileNftFilters = () => ({
  type: 'RESET_PROFILE_NFTS_LISTING_FILTERS',
});

export const addMarketplaceNftsFilters = (filters) => ({
  type: 'SET_NFTS_LISTING_FILTERS',
  filters,
});

export const resetPopularCollectionFilter = () => ({
  type: 'RESET_POPULAR_LISTING_QUERY',
});

export const resetNftsFilters = () => ({
  type: 'RESET_NFTS_LISTING_FILTERS',
});
export const addMarketplaceCollectionCategories = (collection_query) => ({
  type: 'SET_COLLECTION_LISTING_QUERY',
  collection_query,
});
export const resetCollectionQueryFilter = (collection_query) => ({
  type: 'RESET_COLLECTION_LISTING_QUERY',
  collection_query,
});

export const toggleMarketplaceNftsLoading = (is_loading) => ({
  type: 'TOGGLE_LOADING',
  is_loading,
});
export const toggleMarketplaceLoadMoreLoading = (loading) => ({
  type: 'TOGGLE_LOAD_MORE_LOADING',
  load_more: loading,
});
export const toggleCollectionListingLoading = (is_loading) => ({
  type: 'TOGGLE_LOADING',
  is_loading,
});
export const toggleCollectionLoadMoreLoading = (loading) => ({
  type: 'TOGGLE_LOAD_MORE_LOADING',
  load_more: loading,
});

export const trendingCollectionList = (payload) => ({
  type: 'TRENDING_COLLECTIONS_LIST',
  payload,
});

// export const topSellerList = (payload) => ({
//   type: 'TOP_SELLER_LIST',
//   payload,
// });

export const topProfileList = (payload) => ({
  type: 'TOP_PROFILE_LIST',
  payload,
});

export const topBuyerList = (payload) => ({
  type: 'TOP_BUYER_LIST',
  payload,
});

export const categoriesList = (payload) => ({
  type: 'CATEGORIES',
  payload,
});

export const newlyListedNfts = (payload) => ({
  type: 'NEW_ITEMS',
  payload,
});

export const trendingColections = (payload) => ({
  type: 'TRENDING_COLLECTIONS_LIST',
  payload,
});

export const topCollections = (payload) => ({
  type: 'TOP_COLLECTIONS_LIST',
  payload,
});

export const collectionsData=(payload)=>({
  type: 'COLLECTIONS_TOP_TRENDING_LIST',
  payload,
})

export const addCollectionAnalytics = (analytics) => ({
  type: 'SET_COLLECTION_ANALYTICS',
  analytics,
});

export const toggleAnalyticsLoading = (analytics_loading) => ({
  type: 'SET_COLLECTION_ANALYTICS_LOADING',
  analytics_loading,
});

export const fetchCollectionAnalytics = (collectionID) => {
  return (dispatch) => {
    dispatch(toggleAnalyticsLoading(true));
    try {
      return Service.getCall(`${ANALYTICS_API}?collectionID=${collectionID}`).then((response) => {
        dispatch(
          addCollectionAnalytics({
            analytics: response?.data,
          }),
        );
        dispatch(toggleAnalyticsLoading(false));
      });
    } catch (error) {
      dispatch(toggleAnalyticsLoading(false));
      dispatch(
        addCollectionAnalytics({
          analytics: {},
        }),
      );
    }
  };
};

export const fetchCollectionsOfSlider = async (apiUrl, body) => {
  try {
    //const bodyData = collectionWhereBasedQuery(updatedQuery);
    const response = await Service.getCall(`${apiUrl}?${body}`);
    let results = response.data.rows;
    return results;
  } catch (error) {
    console.log('collections:::', error);
    return;
  }
};

export const fetchMarketplaceAssets = (body) => {
  return (dispatch) => {
    return Service.postCall(GCP_API_URL + 'marketplaceListing', body)
      .then((response) => {
        let payload = response.data.payload;
        let isLoadMore = true;
        if (payload.data.length === 0) {
          isLoadMore = false;
        }

        if (body.page > 1) {
          dispatch(
            addMoreAssets({
              assets: payload.data,
              total: payload.total,
              is_loading: false,
              laod_more: isLoadMore,
            }),
          );
        }

        if (body.page === 1) {
          dispatch(
            addMarketplaceAssets({
              assets: payload.data,
              total: payload.total,
              is_loading: false,
              laod_more: isLoadMore,
            }),
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error);
          dispatch(
            addMarketplaceAssets({
              assets: [],
              total: 0,
              is_loading: false,
              laod_more: false,
            }),
          );
        }
      });
  };
};

export const fetchMarketplaceNfts = (apiUrl, body, sortChanged) => {
  return async (dispatch, getState) => {
    sortChanged
      ? dispatch(toggleMarketplaceNftsLoading(true))
      : dispatch(toggleMarketplaceLoadMoreLoading(true));
    try {
      // const response = await axios.get(`${apiUrl}?${body}`, config);
      const response = await Service.getCall(`${apiUrl}?${body}`);
      let results = response.data.rows;
      results = results.map((data, i) => {
        const nft = data;
        return {
          name: nft.name ?? 'Unnamed',
          image: nft.image ?? '',
          thumbnail: nft.thumbnail ?? '',
          animation_url: nft.animation_url ?? '',
          id: nft.id ?? '',
          type: nft.type ?? '',
          owner_id: nft.owner_id ?? '',
          token_id: String(nft.token_id) ?? '',
          contract_address: nft.contract_address ?? '',
          amount: Number(nft.amount) ?? null,
          protocol: nft?.protocol ?? '',
          //sort: data.sort ?? '',
        };
      });
      dispatch(
        addMoreNfts({
          nfts: [...results],
          total: response.data.count,
        }),
      );
      sortChanged
        ? dispatch(toggleMarketplaceNftsLoading(false))
        : dispatch(toggleMarketplaceLoadMoreLoading(false));
    } catch (error) {
      if (error.response) {
        sortChanged
          ? dispatch(toggleMarketplaceNftsLoading(false))
          : dispatch(toggleMarketplaceLoadMoreLoading(false));
        dispatch(
          addMarketplaceNfts({
            nfts: [],
            total: 0,
          }),
        );
      }
    }
  };
};
// export const fetchMarketplaceNfts = (apiUrl, body, sortChanged) => {
//   return async (dispatch, getState) => {
//     sortChanged
//       ? dispatch(toggleMarketplaceNftsLoading(true))
//       : dispatch(toggleMarketplaceLoadMoreLoading(true));
//     try {
//       const response = await Service.postCall(apiUrl, body, {
//         headers: {
//           Authorization: ELASTIC_SEARCH_SEARCH_KEY,
//           'Content-Type': 'application/json',
//         },
//       });

//       let results = response.data.hits.hits;
//       results = results.map((data, i) => {
//         const nft = data._source;
//         return {
//           name: nft.name ?? 'Unnamed',
//           image: nft.image ?? '',
//           thumbnail: nft.thumbnail ?? '',
//           animation_url: nft.animation_url ?? '',
//           id: nft.id ?? '',
//           type: nft.type ?? '',
//           owner_id: nft.owner_id ?? '',
//           token_id: nft.token_id ?? '',
//           contract_address: nft.contract_address ?? '',
//           amount: Number(nft.amount) ?? null,
//           sort: data.sort ?? '',
//         };
//       });
//       // TEMP Chapi

//       dispatch(
//         addMoreNfts({
//           nfts: [...results],
//           total: response.data.hits.total.value,
//         }),
//       );
//       sortChanged
//         ? dispatch(toggleMarketplaceNftsLoading(false))
//         : dispatch(toggleMarketplaceLoadMoreLoading(false));
//     } catch (error) {
//       if (error.response) {
//         sortChanged
//           ? dispatch(toggleMarketplaceNftsLoading(false))
//           : dispatch(toggleMarketplaceLoadMoreLoading(false));
//         dispatch(
//           addMarketplaceNfts({
//             nfts: [],
//             total: 0,
//           }),
//         );
//       }
//     }
//   };
// };
export const fetchCurrentMinterCollectionsFromElasticSearch = async (bodyData) => {
  try {
    const URL = `${ELASTIC_SEARCH_API_URL}${ELASTIC_SEARCH_COLLECTION_URI}`;
    const usercollections = await Service.postCall(URL, bodyData, {
      headers: {
        Authorization: ELASTIC_SEARCH_SEARCH_KEY,
        'Content-Type': 'application/json',
      },
    });

    let results = usercollections.data.hits.hits;

    let collections = [];

    results.map((data, i) => {
      const collection = data._source;
      collections.push({
        name: collection.collection_name ?? 'Unnamed',
        image: collection.banner_img,
        contract_address: collection.contract_address,
        protocol: collection.protocol ?? 'ERC-721',
        sort: data.sort ?? '',
      });
    });
    return collections;
  } catch (error) {
    console.log('Error fetchCurrentMinterCollectionsFromElasticSearch::', error);
    return [];
  }
};
export const fetchNftListingFilters = (contractAddress) => {
  return async (dispatch) => {
    try {
      const results = await Common.getSingleDocument(
        `/stores/${STORE_ID}/collections/${contractAddress}/filters`,
        'data',
      );
      if (results !== null) {
        const sortedData = Object.keys(results)
          .sort()
          .reduce((acc, key) => {
            acc[key] = results[key];
            return acc;
          }, {});
        dispatch(
          addNftListingFilters({
            filters: sortedData,
          }),
        );
      } else {
        dispatch(
          addNftListingFilters({
            filters: {},
          }),
        );
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };
};

export const fetchCollection = (contractAddress) => {
  return async () => {
    try {
      const collection = await Common.getAllDocuments(
        `/stores/${STORE_ID}/collections`,
        [
          {
            key: 'contract_address',
            op: '==',
            value: contractAddress,
          },
        ],
        null,
      );
      return collection?.docs?.length > 0 ? collection.docs[0] : null;
    } catch (error) {
      return null;
    }
  };
};

export const fetchCollectionListing = (apiUrl, body, sortChanged) => {
  return async (dispatch) => {
    sortChanged
      ? dispatch(toggleCollectionListingLoading(true))
      : dispatch(toggleCollectionLoadMoreLoading(true));
    try {
      const response = await Service.getCall(`${apiUrl}?${body}`);
      // const category = state.collection_listing_query?.categories ?? [];
      let results = response?.data?.rows;
      results = results.map((data, i) => {
        const collection = data;
        return {
          name: collection.collection_name ?? collection.name ?? 'Unnamed',
          bannerImage: collection.banner_img,
          image: collection.image,
          thumbnail: collection?.thumbnail ?? '',
          logoImage: collection.logo_img,
          contract_address: collection.contract_address,
          sort: data?.sort ?? '',
          floor_price: collection.collection_stats?.floor_price ?? 0,
          total_volume: collection.collection_stats?.one_day ?? 0,
          total_nfts: collection?.collection_stats?.total_nfts ?? 0,
          unique_owners: collection?.collection_stats?.unique_owners ?? 0,
          protocol: collection?.protocol ?? '',
        };
      });

      dispatch(
        addMarketplaceCollections({
          collections: [...results],
          total: response.data?.count,
        }),
      );
      sortChanged
        ? dispatch(toggleCollectionListingLoading(false))
        : dispatch(toggleCollectionLoadMoreLoading(false));
    } catch (error) {
      dispatch(
        addMarketplaceCollections({
          collections: [],
          total: 0,
        }),
      );
      sortChanged
        ? dispatch(toggleCollectionListingLoading(false))
        : dispatch(toggleCollectionLoadMoreLoading(false));

      console.log('Error', error);
    }
  };
};

export const fetchAllCollectionListing = (apiUrl, params, sortChanged, setMore) => {
  return async (dispatch) => {
    sortChanged
      ? dispatch(toggleCollectionListingLoading(true))
      : dispatch(toggleCollectionLoadMoreLoading(true));
    try {
      const response = await Service.getCall(`${apiUrl}?${params}`);
      // const category = state.collection_listing_query?.categories ?? [];
      if (response?.data?.length === 0) {
        setMore(false);
      }
      let results = response?.data;
      results = results.map((data, i) => {
        const collection = data;
        return {
          name: collection.collection_name ?? collection.name ?? 'Unnamed',
          bannerImage: collection.banner_img,
          image: collection.image,
          thumbnail: collection?.thumbnail ?? '',
          logoImage: collection.logo_img,
          contract_address: collection.contract_address,
          sort: data?.sort ?? '',
          floor_price: collection.collection_stats?.floor_price ?? 0,
          total_volume: collection.collection_stats?.one_day ?? 0,
          total_nfts: collection?.collection_stats?.total_nfts ?? 0,
          unique_owners: collection?.collection_stats?.unique_owners ?? 0,
          protocol: collection?.protocol ?? '',
        };
      });
      dispatch(
        addMarketplaceCollections({
          collections: [...results],
          total: response?.data?.length,
        }),
      );
      sortChanged
        ? dispatch(toggleCollectionListingLoading(false))
        : dispatch(toggleCollectionLoadMoreLoading(false));
    } catch (error) {
      dispatch(
        addMarketplaceCollections({
          collections: [],
          total: 0,
        }),
      );
      sortChanged
        ? dispatch(toggleCollectionListingLoading(false))
        : dispatch(toggleCollectionLoadMoreLoading(false));

      console.log('Error', error);
    }
  };
};

// export const fetchCollectionListing = (apiUrl, body, sortChanged) => {
//   return async (dispatch) => {
//     sortChanged
//       ? dispatch(toggleCollectionListingLoading(true))
//       : dispatch(toggleCollectionLoadMoreLoading(true));
//     try {
//       const response = await Service.postCall(apiUrl, body, {
//         headers: {
//           Authorization: ELASTIC_SEARCH_SEARCH_KEY,
//           'Content-Type': 'application/json',
//         },
//       });
//       // const category = state.collection_listing_query?.categories ?? [];
//       let results = response.data.hits.hits;
//       results = results.map((data, i) => {
//         const collection = data._source;
//         return {
//           name: collection.collection_name ?? collection.name ?? 'Unnamed',
//           bannerImage: collection.banner_img,
//           image: collection.image,
//           thumbnail: collection.thumbnail,
//           logoImage: collection.logo_img,
//           contract_address: collection.contract_address,
//           sort: data.sort ?? '',
//           floor_price: collection.stats?.floor_price ?? 0,
//           total_volume: collection.stats?.total_volume ?? 0,
//         };
//       });

//       dispatch(
//         addMarketplaceCollections({
//           collections: [...results],
//           total: response.data.hits.total?.value,
//         }),
//       );
//       sortChanged
//         ? dispatch(toggleCollectionListingLoading(false))
//         : dispatch(toggleCollectionLoadMoreLoading(false));
//     } catch (error) {
//       dispatch(
//         addMarketplaceCollections({
//           collections: [],
//           total: 0,
//         }),
//       );
//       sortChanged
//         ? dispatch(toggleCollectionListingLoading(false))
//         : dispatch(toggleCollectionLoadMoreLoading(false));

//       console.log('Errrpr', error);
//     }
//   };
// };

export const fetchNftsCollection = (
  filters = null,
  pageSize = 10,
  orderBy = 'created_at',
  order = 'desc',
) => {
  return async (dispatch, getState) => {
    const state = getState();
    const existingData = state.trending_collections;
    if (!existingData) {
      try {
        filters = Common.convertObjectToArray(filters);
        const response = await Common.getAllDocuments(`/stores/${STORE_ID}/nfts`, filters);
        dispatch(
          newlyListedNfts({
            data: response.docs,
            is_loading: false,
          }),
        );
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    }
  };
};

// export const fetchTrendingCollection = (period) => {
//   return async (dispatch) => {
//     try {
//       const response = await Common.getAllDocuments(
//         `/stores/${STORE_ID}/analytics/tranding_collections/${period}`,
//         [],
//         'position',
//         'asc',
//         12,
//       );
//       dispatch(
//         trendingColections({
//           data: response.docs,
//           is_trending_loading: false,
//         }),
//       );
//     } catch (error) {
//       console.error('Error fetching data: ', error);
//     }
//   };
// };

// export const fetchTrendingCollection = (apiUrl, body) => {
//   return async (dispatch) => {
//     try {
//       const response = await Service.getCall(`${apiUrl}?${body}`);
//       dispatch(
//         trendingColections({
//           data: response.data,
//           is_trending_loading: false,
//         }),
//       );
//     } catch (error) {
//       console.error('Error fetching data: ', error);
//     }
//   };
// };

export const fetchCollectionData = (apiUrl, body,collectionType,period) => {
  return async (dispatch) => {
    try {
      const response = await Service.getCall(`${apiUrl}/${collectionType}?${body}`);
      dispatch(
        collectionsData({
          data: response.data,
          period: period,
          key: collectionType,
          is_collections_loading: false,
        }),
      );
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
};

// export const fetchTopCollection = (period) => {
//   return async (dispatch) => {
//     try {
//       const response = await Common.getAllDocuments(
//         `/stores/${STORE_ID}/analytics/top_collections/${period}`,
//         [],
//         'position',
//         'asc',
//         12,
//       );
//       dispatch(
//         topCollections({
//           data: response.docs,
//           is_top_loading: false,
//         }),
//       );
//     } catch (error) {
//       console.error('Error fetching data: ', error);
//     }
//   };
// };

export const fetchMarketplaceCollections = (
  filters = null,
  pageSize = 20,
  orderBy = 'created_at',
  order = 'desc',
  lastDoc = null,
) => {
  return async (dispatch, getState) => {
    const state = getState();
    const existingData = state.marketplace_listing.assets;
    const last_doc = state.marketplace_listing.last_doc;
    const coll = collection(db, `/stores/${STORE_ID}/collections`);
    const snapshot = await getCountFromServer(coll);

    if (!existingData || existingData?.length === 0) {
      try {
        filters = Common.convertObjectToArray(filters);
        const response = await Common.getAllDocuments(
          `/stores/${STORE_ID}/collections`,
          filters,
          orderBy,
          order,
          pageSize,
          last_doc,
        );

        dispatch(
          addMoreAssets({
            assets: response.docs,
            is_loading: false,
            last_doc: response.lastDoc,
            total_collections: snapshot.data().count,
          }),
        );
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    } else {
      try {
        const response = await Common.getAllDocuments(
          `/stores/${STORE_ID}/collections`,
          [],
          orderBy,
          order,
          pageSize,
          last_doc,
        );

        let payload = response.docs;
        let isLoadMore = true;
        if (payload.length === 0) {
          isLoadMore = false;
        }
        if (filters.page > 1) {
          dispatch(
            addMoreAssets({
              assets: payload,
              total: payload.length,
              is_loading: false,
              laod_more: isLoadMore,
              last_doc: response?.lastDoc,
            }),
          );
        }

        if (filters.page === 1) {
          dispatch(
            addMarketplaceAssets({
              assets: payload,
              total: payload.length,
              is_loading: false,
              laod_more: isLoadMore,
              last_doc: response?.lastDoc,
            }),
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
};

export const fetchLatestCollections = (
  filters = null,
  pageSize = 5,
  orderBy = null,
  order = null,
  lastDoc = null,
) => {
  return async (dispatch, getState) => {
    const state = getState();
    const existingData = state.marketplace_listing.latest;
    if (!existingData || existingData?.length === 0) {
      try {
        filters = Common.convertObjectToArray(filters);
        const response = await Common.getAllDocuments(
          `/stores/${STORE_ID}/collections`,
          filters,
          orderBy,
          order,
          pageSize,
          lastDoc,
        );
        dispatch(
          addLatestCollections({
            data: response.docs,
            is_loading: false,
          }),
        );
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    }
  };
};

export const fetchFeaturedCollections = (
  filters = null,
  pageSize = 5,
  orderBy = null,
  order = null,
  lastDoc = null,
) => {
  return async (dispatch, getState) => {
    const state = getState();
    const existingData = state.marketplace_listing.latest;
    if (!existingData || existingData?.length === 0) {
      try {
        filters = Common.convertObjectToArray(filters);
        const response = await Common.getAllDocuments(
          `/stores/${STORE_ID}/collections`,
          filters,
          orderBy,
          order,
          pageSize,
          lastDoc,
        );
        dispatch(
          addLatestCollections({
            data: response.docs,
            is_loading: false,
          }),
        );
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    }
  };
};

export const fetchTopProfiles = (period, path) => {
  return async (dispatch) => {
    try {
      const response = await Common.getAllDocuments(
        `/stores/${STORE_ID}/analytics/${path}/${period}`,
        [],
        'amount',
        'desc',
        12,
      );

      dispatch(
        topProfileList({
          data: response.docs,
          period: period,
          key: path,
          is_top_profile_loading: false,
        }),
      );
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
    // }
  };
};

// export const fetchTopBuyers = (period) => {
//   return async (dispatch) => {
//     try {
//       const response = await Common.getAllDocuments(
//         `/stores/${STORE_ID}/analytics/top_buyer/${period}`,
//         [],
//         'amount',
//         'desc',
//         12,
//       );
//       dispatch(
//         topBuyerList({
//           data: response.docs,
//           is_top_buyer_loading: false,
//         }),
//       );
//     } catch (error) {
//       console.error('Error fetching data: ', error);
//     }
//     // }
//   };
// };

export const fetchCategories = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const existingData = state.marketplace_listing.categories;
    if (!existingData || existingData?.length === 0) {
      try {
        const response = await Common.getAllDocuments(
          `/stores/${STORE_ID}/categories`,
          [],
          'name',
          'asc',
        );
        dispatch(
          categoriesList({
            data: response.docs,
          }),
        );
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    }
  };
};
