let initialState = {
  assets: [],
  latest: [],
  // trending_collections: [],
  // top_collections: [],
  top_profile_list: {},
  collections_list: {},
  // top_seller_list: [],
  // top_buyer_list:[],
  categories: [],
  total: 0,
  is_loading: true,
  is_collections_loading: true,
  is_top_loading: true,
  // is_top_seller_loading: true,
  is_top_profile_loading: true,
  load_more: true,
  last_doc: null,
  total_collections: 0,
};

const marketplaceListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MARKETPLACE_LISTING':
      return {
        assets: action.payload.assets,
        total: action.payload.total,
        is_loading: action.payload.is_loading,
        load_more: action.payload.load_more,
      };
    case 'ADD_ASSETS':
      return {
        ...state,
        assets: [...state.assets, ...action.payload.assets],
        total: action.payload.total,
        is_loading: action.payload.is_loading,
        last_doc: action.payload.last_doc,
        load_more: action.payload.load_more,
        total_collections: action.payload.total_collections,
      };

    case 'TOGGLE_LOADING':
      return {
        ...state,
        is_loading: action.is_loading,
      };
    case 'TRENDING_COLLECTIONS_LIST':
      return {
        ...state,
        trending_collections: action.payload.data,
        is_trending_loading: action.payload.is_trending_loading,
      };
    // case 'TOP_COLLECTIONS_LIST':
    //   return {
    //     ...state,
    //     top_collections: action.payload.data,
    //     is_top_loading: action.payload.is_top_loading,
    //   };
    case 'COLLECTIONS_TOP_TRENDING_LIST':
      return {
        ...state,
        collections_list: {
          ...state.collections_list,
          [action.payload.key]: {
            ...state.collections_list[action.payload.key],
            [action.payload.period]: state.collections_list[action.payload.key]?.[action.payload.period]
              ? state.collections_list[action.payload.key][action.payload.period]
              : {
                  data: [
                    ...(state.collections_list[action.payload.key]?.[action.payload.period]?.data || []),
                    ...action.payload.data
                  ]
                }
          }
        },
        is_collections_loading: action.payload.is_collections_loading,
      };
    
    case 'LATEST_COLLECTIONS_LIST':
      return {
        ...state,
        latest: action.payload.data,
      };
    case 'TOP_PROFILE_LIST':
      return {
        ...state,
        top_profile_list: {
          ...state.top_profile_list,
          [action.payload.key]: {
            ...state.top_profile_list[action.payload.key],
            [action.payload.period]: {
              data: state.top_profile_list[action.payload.key]?.[action.payload.period]
                ? state.top_profile_list[action.payload.key][action.payload.period].data
                : [
                    ...(state.top_profile_list[action.payload.key]?.[action.payload.period]?.data ||
                      []),
                    ...action.payload.data,
                  ],
            },
          },
        },
        is_top_profile_loading: action.payload.is_top_seller_loading,
      };

    case 'TOP_BUYER_LIST':
      return {
        ...state,
        top_buyer_list: action.payload.data,
        is_top_buyer_loading: action.payload.is_top_seller_loading,
      };

    case 'CATEGORIES':
      return {
        ...state,
        categories: action.payload.data,
      };
    default:
      return state;
  }
};

export default marketplaceListingReducer;
